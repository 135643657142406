import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalConstants } from '../global-constants';
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  apps;

  constructor(
    private route: ActivatedRoute,
    private router: Router) {   }

  ngOnInit() {
    this.apps = environment.apps;
  }

  openPage() {
    this.router.navigate(['/listproject']);
  }

}
