// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apps: [
    {
      title: "Onboarding",
      mainText: "This application is used to gather and distribute due diligence documentation while onboarding new partner practices.",
      // descriptionText: "Primary users are Practice Managers, M&A representatives and On Boarding specialists. Please click the button to continue to the application.",
      link: "https://onboarding.devrbcompanion.com/"
    },
    {
      title: "Master Data Configuration",
      mainText: "This admin application is used to map data connections between our business support systems.",
      descriptionText: "",
      link: "https://master.devrbcompanion.com/"
    },
    {
      title: "Employee Service",
      mainText: "This application provides a centralized place to view team member employee profiles and related employment information.",
      descriptionText: "",
      link: "https://employee.devrbcompanion.com/"
    }

  ]

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
